<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <feed v-if="!loading"
        :feed-list-uid="professional.feedList"
        :target-feed-lists-uid="[professional.feedList, space.feedList]"
  />
</template>

<script>
  import Feed from '@/modules/feeds/components/Feed'
  import {FeedList, Space} from '@/models'
  import { retryablePromises } from '@/utils/promise'

  export default {
    name: 'ProfessionalFeed',
    components: {Feed},
    props: {
      professional: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        loading: true
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      },
      feedList() {
        return FeedList.get(this.professional.feedList)
      }
    },
    async created() {
      try {
        await retryablePromises([
          () => this.space.fetchFeedList(),
          () => this.professional.fetchFeedList()
        ], async () => {
          return await this.$confirm('Une erreur est survenue', 'retry')
        })
      } finally {
        this.loading = false
      }

      this.isMyself = this.professional.uid === this.$auth.user.sub
      this.loadPage = false
    },
    methods: {
      async loadFeed() {

      },
      onScroll(e) {
        clearTimeout(this.headerTimer)
        this.headerTimer = setTimeout(() => {
          this.headerColor = e.target.scrollingElement.scrollTop < 80 ? 'transparent' : 'header-bg'
        }, 250)
      }
    }
  }
</script>
